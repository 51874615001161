$primaryGreen: #35bb78;
$backLightGreen: #79b999; 
$altGreen1: #0bf46e;
$altGreen2: #91F40B;
$altGreen3: #008543;
$primaryPurple: #13032c;
$altPurple1: #000085;
$altPurple2: #430085;
$altPurple3: #850042;
$primaryFont: #E6E6E4;
$altBackground: #2F2E33;

$menuFontWeight: 700;
$menuPadding: 13px;
$logoHeight: 64px;

:export {
    primaryGreen: $altGreen3;
    backLightGreen: $backLightGreen;
    darkBackground: $altBackground;
    primaryFont: $primaryFont;
    primaryPurple: $primaryPurple;
    menuFontWeight: $menuFontWeight;
    menuPadding: $menuPadding;
    logoHeight: $logoHeight;
}