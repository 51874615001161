@import "../variables.scss";

.MuiListItem-gutters {
  height: 64px;
  padding: 32px;
}

.MuiListItem-button {
  &:hover {
    color: $primaryPurple
  }
}