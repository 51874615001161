@import "../variables.scss";

.coming-soon {
  color: $primaryFont;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 128px;
  text-align: center;
  margin-top: 64px;
  > div { font-size: 32px;}
}

.coming-soon-mobile {
  font-size: 64px;
  > div { font-size: 16px;}
}